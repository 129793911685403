import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import VmTable from "../../components/shared-table";
import VmButton from "../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation } from "../../constants/options";
import Breadcrumb from "../../components/shared-breadcrumb";
import VmModal from "../../components/shared-modal";
import { toJS } from "mobx";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const ExamSettingPage = observer(({ location }) => {
  const { rootStore, academicStore, studentStore, questionStore, examStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showStudentAccessModal, setShowStudentAccessModal] = useState<boolean>(false);
  const [showFullQuestionModal, setShowFullQuestionModal] = useState<boolean>(false);
  // Determine is update or create
  const [currentExamId, setCurrentExamId] = useState<number>(0);
  const [targetExamSetting, setTargetExamSetting] = useState<any>();
  const [difficulty, setDifficulty] = useState<string>("0");
  const [quantity, setQuantity] = useState<string>("0");
  const [questionCatId, setQuestionCatId] = useState<string>("0");
  const [knowledegePointId, setknowledegePointId] = useState<string>("0");
  const [markPerQuestion, setMarkPerQuestion] = useState<string>("0");
  const [subjectId, setSubjectId] = useState<string>("0");
  const [studentIdList, setStudentIdList] = useState<any>([]);
  const [classCode, setClassCode] = useState<string>(" ");
  // Generated Questions Variable
  const [examGeneratedQuestions, setExamGeneratedQuestions] = useState<any>([]);
  const [currentSettingId, setCurrentSettingId] = useState<number>(0);
  const [questionIdList, setQuestionIdList] = useState<any>([]);
  const [activeFunction, setActiveFunction] = useState<any>([]);
  const onChangeKnowledegePt = (event: SelectChangeEvent) => {
    setknowledegePointId(event.target.value as string);
  };
  const onChangeQuestionCatId = (event: SelectChangeEvent) => {
    setQuestionCatId(event.target.value as string);
  };
  const onChangeClassCode = (event: SelectChangeEvent) => {
    setClassCode(event.target.value as string);
  };
  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0
      || ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0);
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) == 0
    || ApiDataResponseListLengthCheck(ApiDataListType.STUDENT) != studentStore.studentProfileList.data.length) {
      studentStore.getStudentProfileList(0, 0);
    }
  }, []);

  useEffect(() => {
    examStore.getExamList(0, 0).then((response) => {
      let examId = 0;
      if (location.state && location.state.examId) {
        examId = location.state.examId;
      }
      onToggleExamSettingList(examId == 0 ? response.data[0].id : examId);
    });
  }, [location.state])

  const onToggleExamSettingList = (id: number) => {
    examStore.getExamSettingByExamId(id);
    setCurrentExamId(id);
    setSubjectId(examStore.examList.data.find(e => e.id == id).subjectId);
    questionStore.getQuestionCategoryListBySubjectId(
      examStore.examList.data.find(e => e.id == id).subjectId, 0, 0
    );
  }

  const onActionExamSetting = () => {
    let req: any = {
      id: 0,
      difficulty,
      quantity,
      knowledgePointId: knowledegePointId,
      markPerQuestion,
      examId: currentExamId,
    }
    if (targetExamSetting) {
      req.id = targetExamSetting.id;
      examStore.updateExamSetting(req).then(() => {
        rootStore.notify(t('EXAM') + t('SETTING_B') + t('UPDATED_B'), 'success');
        onToggleExamSettingList(currentExamId);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      examStore.createExamSetting(req).then(() => {
        rootStore.notify(t('EXAM') + t('SETTING_B') + t('CREATED_B'), 'success')
        onToggleExamSettingList(currentExamId);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: any) => {
    if (id) {
      let target = examStore.examSettingList.find(e => e.id == id);
      setTargetExamSetting(target);
      setDifficulty(target.difficulty);
      setQuantity(target.quantity);
      setMarkPerQuestion(target.markPerQuestion);
      setSubjectId(target.subjectId);
      questionStore.getQuestionCategoryListBySubjectId(target.subjectId, 0, 0)
        .then(() => {
          setQuestionCatId(target.questionCategoryId);
          setknowledegePointId(target.knowledgePointId);
        });
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setShowStudentAccessModal(false);
    setShowFullQuestionModal(false);
    setTargetExamSetting(null);
    setDifficulty("0");
    setQuantity("0");
    setknowledegePointId("0");
    setQuestionCatId("0")
    setMarkPerQuestion("0");
    setClassCode(" ");
    setStudentIdList([]);
    setQuestionIdList([]);
  }

  const handleDeleteModal = (id: number) => {
    let target = examStore.examSettingList.find(es => es.id == id);
    setTargetExamSetting(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('EXAM') + t('SETTING_B') + t('MODULE_B')}
      pageHeading={t('EXAM') + t('SETTING_B')}
      breadCrumb={[
        { label: t('EXAM') + t('MODULE_B'), to: "" },
        { label: t('EXAM') + t('SETTING_B'), to: "/exam/exam-setting" }
      ]}
      showHeadingButton={false}
    >
      {/* Setting Section */}
      <div className="grid grid-cols-4 gap-4">
        <div className="max-h-96 overflow-auto">
          {
            examStore.loading ?
              <>
                <div className="text-center my-4"><Skeleton /></div>
                <div className="text-center my-4"><Skeleton /></div>
                <div className="text-center my-4"><Skeleton /></div>
              </>
              :
              examStore.examList.data.length > 0 &&
              examStore.examList.data.map((exam: any, index: number) => (
                <Box key={`exam_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentExamId == exam.id  ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleExamSettingList(exam.id)}
                  >
                    {exam.examCode}
                  </button>
                </Box>
              ))
          }
        </div>
        <div className="col-span-3">
          <div className="flex mb-4">
            <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content' }}
              onClick={() => handleShowModal()}
              disabled={currentExamId == 0}
            >
              {t('ADD_A') + t('EXAM_SETTING')}
            </VmButton>
            <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content', marginLeft: 4 }}
              disabled={currentExamId == 0}
              onClick={() => {
                examStore.getEligibleStudentListByExamId(currentExamId)
                  .then((response) => {
                    let idList: any[] = [];
                    response.map((classCode: any) => {
                      classCode.studentList.filter(s => s.examAccess == true)
                        .map((s) => {
                          idList.push(s.id)
                        });
                    })
                    setStudentIdList(idList);
                  });
                setShowStudentAccessModal(true);
              }}
            >
              {t('MANAGE_STUDENT_ACCESS')}
            </VmButton>
            {
              examStore.examList.length > 0 && currentExamId != 0 &&
              examStore.examList.find(e => e.id == currentExamId).sameQuestionExam &&
              <VmButton
                className="bg-highlight text-white px-4 py-2"
                style={{ height: 'fit-content', marginLeft: 4 }}
                onClick={() => examStore.generateExamQuestion(currentExamId)
                  .then((response) => {
                    setExamGeneratedQuestions(response);
                    setCurrentSettingId(response[0].settingId);
                  })}
                disabled={examStore.examSettingList.length == 0}
              >
                {t('GENERATE_QUESTIONS')}
              </VmButton>
            }
          </div>
          <VmTable
            loading={examStore.examSettingLoading}
            page={0}
            thead={["ID", t('DIFFICULTY'), t('QUANTITY'), t('MARK_PER_QUESTION'), t('ACTION')]}
            tbody={examStore.examSettingList.length > 0 &&
              examStore.examSettingList.map((es: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`exam_list_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>Level {es.difficulty}</td>
                    <td className={TD_NORMAL}>{es.quantity}</td>
                    <td className={TD_NORMAL}>{+es.markPerQuestion > 1 ? es.markPerQuestion + " Marks" : es.markPerQuestion + " Mark"}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowModal(es.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(es.id)}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </div>
      </div>

      {/* Generated Question Section */}
      {examGeneratedQuestions.length > 0 &&
        <>
          <div className="flex justify-between mt-6">
            <Typography variant="h5" fontWeight={'bold'} marginBottom={2}>{t('GENERATED_QUESTIONS')}:</Typography>
          </div>
          <Divider sx={{ marginBottom: 2 }} />
          <div className="grid grid-cols-3 gap-4">
            <div>
              {examGeneratedQuestions.map((setting: any, index: number) => (
                <Box key={`setting_generated_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentSettingId == setting.settingId && INLINE_SELECTED} ${INLINE_SELECT_OPTION}`}
                    onClick={() => setCurrentSettingId(setting.settingId)}
                  >
                    {t('SETTING_A')}{index + 1}: {t("DIFFICULTY")}{setting.difficulty}/{t("QUANTITY")}{setting.quantity}/{setting.markPerQuestion}{t('MARK_B')}
                  </button>
                </Box>
              ))}
            </div>
            <div className="col-span-2">
              <VmButton
                className="bg-highlight text-white px-4 py-2 mb-4"
                style={{ height: 'fit-content' }}
                onClick={() => {
                  setQuestionIdList(examGeneratedQuestions.find(
                    egq => egq.settingId == currentSettingId).questionList.map(q => q.id)
                  )
                  examStore.getFullQuestionListByExamId(currentExamId);
                  setShowFullQuestionModal(true);
                }}
                disabled={currentExamId == 0}
              >
                {t('EDIT_QUESTIONS')}
              </VmButton>
              <VmTable
                loading={examStore.loading}
                thead={["ID", t('QUESTIONS'), t('MARK'), t('TYPE')]}
                tbody={currentSettingId != 0 && examGeneratedQuestions.find(egq => egq.settingId == currentSettingId).questionList
                  && examGeneratedQuestions.find(egq => egq.settingId == currentSettingId).questionList
                    .map((question: any, index: number) => (
                      <>
                        <Box marginY={1} />
                        <tr key={`question_list_${index}`}>
                          <td className={TD_FIRST}>{index + 1}</td>
                          <td className={TD_NORMAL}>{question.detail}</td>
                          <td className={TD_NORMAL}>{question.fullMark} {question.fullMark > 1 ? "Marks" : "Mark"}</td>
                          <td className={TD_LAST}>{question.type}</td>
                        </tr>
                        <Box marginY={1} />
                      </>
                    ))}
              />
            </div>
          </div>
        </>}


      {/* Add/Update Setting */}
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetExamSetting ? `${t('UPDATE_A') + t('EXAM_SETTING')}: ${examStore.examList.length > 0 && currentExamId != 0 && examStore.examList.find(e => e.id == currentExamId).examCode}`
          : `${t('ADD_A') + t('EXAM_SETTING')}: ${examStore.examList.length > 0 && currentExamId != 0 && examStore.examList.find(e => e.id == currentExamId).examCode}`}
        buttonLabel={targetExamSetting ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetExamSetting ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionExamSetting}
        disabledCondition={questionStore.questionCatList.length < 1 || academicStore.subjectList.length < 1 ||
          knowledegePointId == "0" || difficulty === "0" || quantity === "0" || markPerQuestion === "0"}
        width={400}
      >
        <FormControl fullWidth>
          <InputLabel>{t('SUBJECT')}</InputLabel>
          {
            academicStore.subjectList.data.length > 0 &&
            <Select
              label={t('SUBJECT')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={subjectId}
              disabled={true}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.subjectList.data.map((sub: any, index: number) => (
                <MenuItem key={`subject_select_${index}`} value={sub.id}>
                  {sub.subjectCode}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>

        {
          questionStore.loading ? <Box sx={{ textAlign: 'center', paddingY: 2 }}><CircularProgress /></Box>
            :
            <>
              <FormControl fullWidth>
                <InputLabel>{t('QUESTION_CATEGORY')}</InputLabel>
                <Select
                  label={t('QUESTION_CATEGORY')}
                  sx={{ width: '100%', marginBottom: 2 }}
                  value={questionCatId}
                  onChange={onChangeQuestionCatId}
                  disabled={subjectId === "0"}
                >
                  <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                  {questionStore.questionCatList.data.map((qCat: any, index: number) => (
                    <MenuItem key={`qCat_select_${index}`} value={qCat.id}>
                      {qCat.categoryCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>{t('KNOWLEDEGE_POINT')}</InputLabel>
                <Select
                  label={t('KNOWLEDEGE_POINT')}
                  sx={{ width: '100%', marginBottom: 2 }}
                  value={knowledegePointId}
                  onChange={onChangeKnowledegePt}
                  disabled={subjectId === "0" || questionCatId === "0"}
                >
                  <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                  {questionStore.questionCatList.data.filter(qCat => qCat.id == questionCatId)
                    .map((qCat: any) => (
                      qCat.questionKnowledgePoints.map((qkp, index: number) => (
                        <MenuItem key={`qKP_select_${index}`} value={qkp.id}>
                          {qkp.code}
                        </MenuItem>
                      ))

                    ))}
                </Select>
              </FormControl>
            </>
        }

        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={quantity}
            type="number"
            label={t('QUANTITY')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setQuantity(value.target.value)}
          />

          <TextField
            value={difficulty}
            type="number"
            label={t('DIFFICULTY')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setDifficulty(value.target.value)}
          />

          <TextField
            value={markPerQuestion}
            type="number"
            label={t('MARK_PER_QUESTION')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setMarkPerQuestion(value.target.value)}
          />
        </div>
      </VmModal>

      {/* Delete Setting */}
      <VmModal
        openModal={showDeleteModal}
        onClose={handleCloseModal}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => examStore.deleteExamSetting(targetExamSetting.id).then(() => {
          rootStore.notify(t('EXAM') + t('SETTING_B') + t('DELETED_B'), 'success')
          examStore.getExamSettingByExamId(currentExamId);
          handleCloseModal();
        }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_EXAM_SETTING')}
        </Typography>
      </VmModal>

      {/* Student Access */}
      <VmModal
        openModal={showStudentAccessModal}
        onClose={handleCloseModal}
        title={`${t('STUDENT_ACCESS')}: ${examStore.examList.data.length > 0 && currentExamId != 0 && examStore.examList.data.find(e => e.id == currentExamId).examCode}`}
        buttonLabel={t('CONFIRM_A') + t('UPDATE')}
        buttonLabelWithoutConfirm={t('UPDATE_NOW')}
        onClickConfirmedButton={() => {
          examStore.editExamAccessSetting({
            examId: currentExamId,
            studentIdList
          });
          handleCloseModal();
        }}
      >
        {examStore.getAccessLoading ?
          <>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
            <div className="text-center my-4"><Skeleton /></div>
          </>
          : <>
            <FormControl fullWidth>
              <InputLabel>{t('CLASS_CODE')}</InputLabel>
              {
                examStore.eligibleStudentList.length > 0 &&
                <Select
                  label={t('CLASS_CODE')}
                  sx={{ width: '80%' }}
                  value={classCode}
                  onChange={onChangeClassCode}
                >
                  <MenuItem value={" "}>{t('NOT_SELECTED')}</MenuItem>
                  {examStore.eligibleStudentList.map((classCode: any, index: number) => (
                    <MenuItem key={`classCode_select_${index}`} value={classCode.classCode}>
                      {classCode.classCode}
                    </MenuItem>
                  ))}
                </Select>
              }
            </FormControl>
            <div className="flex mt-2">
              <VmButton
                className="bg-highlight text-white mr-4 px-4"
                style={{ height: 'fit-content', width: 'fit-content' }}
                disabled={currentExamId == 0 || classCode === " "}
                onClick={() => {
                  setStudentIdList(
                    examStore.eligibleStudentList
                      .find(esl => esl.classCode === classCode).studentList.map(s => s.id));
                }}
              >
                {t('SELECT_ALL')}
              </VmButton>
              <VmButton
                className="bg-highlight text-white px-4"
                style={{ height: 'fit-content', width: 'fit-content' }}
                disabled={currentExamId == 0}
                onClick={() => {
                  setStudentIdList([]);
                }}
              >
                {t('UNSELECT_ALL')}
              </VmButton>
            </div>
            <div className="border p-2 max-h-96 overflow-auto mt-2">
              {examStore.eligibleStudentList.map((classCode: any) => (
                classCode.studentList.map((s: any, index: number) => (
                  <button
                    type="button"
                    className={`hover:bg-highlight hover:text-white w-full text-left px-1 rounded-md text-sm
                        ${studentIdList.includes(s.id) && "bg-highlight text-white"}`}
                    key={`student_select_${index}`}
                    onClick={() => {
                      let tempIdList = studentIdList;
                      if (tempIdList.includes(s.id)) {
                        let i = tempIdList.indexOf(s.id);
                        tempIdList.splice(i, 1);
                      } else {
                        tempIdList.push(s.id);
                      }
                      setStudentIdList(tempIdList);
                      setActiveFunction([]);
                      console.log(tempIdList);
                    }}
                  >
                    {s.firstName + " " + s.lastName}
                  </button>
                ))
              ))
              }
            </div>
            <Typography variant={"body2"} textAlign="center" marginTop={1}>
              <span className="text-red-500">{studentIdList.length}</span>
              <span className="">
                / {examStore.eligibleStudentCount}
                {studentIdList.length > 1 ? t('STUDENTS_B') : t('STUDENT_B')} {t('SELECTED_B')}
              </span>
            </Typography>
          </>}
      </VmModal>

      {/* Full Questions By Exam */}
      <VmModal
        width={400}
        openModal={showFullQuestionModal}
        onClose={handleCloseModal}
        title={`${t('QUESTION_REPO_FOR_EXAM')}${examStore.examList.length > 0 && currentExamId != 0 &&
          examStore.examList.find(e => e.id == currentExamId).examCode}`}
        buttonLabel={t('SAVE_CONFIRM')}
        disabledCondition={currentSettingId == 0 || examGeneratedQuestions.length < 1 ||
          (questionIdList.length != examGeneratedQuestions.find(egq => egq.settingId == currentSettingId).quantity)}
        buttonLabelWithoutConfirm={t('CHANGE_SAVE')}
        onClickConfirmedButton={() => {
          examStore.editExamQuestion([{ examSettingId: currentSettingId, questionIdList }])
            .then(() => {
              examStore.getExamQuestionListByExamId(currentExamId)
                .then((response) => setExamGeneratedQuestions(response));
            });
          handleCloseModal();
        }}
      >
        {examStore.getAccessLoading || examStore.fullQuestionList.length < 1 ? <>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
        </>
          : <>
            <div className="border p-2 max-h-96 overflow-auto mt-2" style={{ marginTop: -10 }}>
              {examStore.fullQuestionList.find(q => q.settingId == currentSettingId) &&
                examStore.fullQuestionList.find(q => q.settingId == currentSettingId)
                  .questionList.map((question: any, index: number) => (
                    <button
                      type="button"
                      className={`hover:bg-highlight hover:text-white w-full text-left px-1 rounded-md text-sm
                        ${questionIdList.includes(question.id) && "bg-highlight text-white"}`}
                      key={`student_select_${index}`}
                      onClick={() => {
                        let tempIdList = questionIdList;
                        if (tempIdList.includes(question.id)) {
                          let i = tempIdList.indexOf(question.id);
                          tempIdList.splice(i, 1);
                        } else tempIdList.push(question.id);
                        setQuestionIdList(tempIdList);
                        setActiveFunction([]);
                      }}
                    >
                      {question.detail}
                    </button>
                  ))}
            </div>
            {currentSettingId > 0 && examGeneratedQuestions.length > 0 &&
              (questionIdList.length != examGeneratedQuestions.find(egq => egq.settingId == currentSettingId).quantity) &&
              <Typography variant={"caption"} marginTop={1}>
                {t('NUMBER_SELECTED_QUESTIONS_EQUAL_TO_QUANTITY_CHECK')} ({examGeneratedQuestions
                  .find(egq => egq.settingId == currentSettingId).quantity}). {t('PLS_ADJUST_QUANTITY')}
              </Typography>
            }
            <Typography variant={"body2"} textAlign="center" marginTop={1}>
              <span className="text-red-500">{questionIdList.length}</span>
              <span className=""> / {currentSettingId != 0 && examStore.fullQuestionList.length > 0 &&
                examStore.fullQuestionList.find(q => q.settingId == currentSettingId).questionList.length}
                {questionIdList.length > 1 ? ' ' + t('QUESTIONS') : ' ' + t('QUESTION')} {t('SELECTED_B')}</span>
            </Typography>
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default ExamSettingPage;
